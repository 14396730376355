export const loadImage = (imgSrc) => {
  return new Promise((resolve) => {
    // eslint-disable-next-line
    const image = new Image();
    image.onload = () => {
      resolve(image);
    };
    image.crossOrigin = "anonymous";
    image.src = imgSrc;
  });
};

export const rotateImage = (img, angle) => {
  return new Promise((resolve) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    switch (angle) {
      case 0:
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        break;
      case 90:
        canvas.width = img.height;
        canvas.height = img.width;
        ctx.translate(img.height, img.width);
        ctx.rotate(-Math.PI / 2);
        ctx.drawImage(img, 0, -img.height);
        break;
      case 180:
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.translate(img.width / 2, img.height / 2);
        ctx.rotate(Math.PI);
        ctx.drawImage(img, -img.width / 2, -img.height / 2);
        break;
      case 270:
        canvas.width = img.height;
        canvas.height = img.width;
        ctx.translate(img.height, 0);
        ctx.rotate(Math.PI / 2);
        ctx.drawImage(img, 0, 0);
        break;
      default:
        resolve(img);
        return;
    }
    canvas.toBlob(
      (blob) => {
        convertBlobToImage(blob).then((image) => {
          resolve(image);
        });
      },
      "image/jpeg",
      1.0
    );
  });
};

const downscaleCanvasImage = (img, canvas, scaleThreshold) => {
  const ctx = canvas.getContext("2d");

  let skipScale = true;
  let width = img.width;
  let height = img.height;
  if (img.width > img.height && img.width > scaleThreshold) {
    height = (scaleThreshold * img.height) / img.width;
    width = scaleThreshold;
    skipScale = false;
  }
  if (img.width < img.height && img.height > scaleThreshold) {
    width = (scaleThreshold * img.width) / img.height;
    height = scaleThreshold;
    skipScale = false;
  }

  if (skipScale) {
    return false;
  }

  canvas.width = width;
  canvas.height = height;
  ctx.drawImage(img, 0, 0, width, height);
  return true;
};

export const downscaleImage = (img, scaleThreshold = 1000) => {
  return new Promise((resolve) => {
    const canvas = document.createElement("canvas");
    if (downscaleCanvasImage(img, canvas, scaleThreshold)) {
      canvas.toBlob(
        (blob) => {
          convertBlobToImage(blob).then((image) => resolve(image));
        },
        "image/jpeg",
        1.0
      );
    } else {
      resolve(img);
    }
  });
};

export const convertBlobToImage = (blob) => {
  return new Promise((resolve) => {
    // eslint-disable-next-line
    const image = new Image();
    image.src = window.URL.createObjectURL(blob);
    image.onload = () => resolve(image);
  });
};
