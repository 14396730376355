import React, {
  FC,
  HTMLProps,
  useEffect,
  useLayoutEffect,
  useRef,
} from "react";
import styles from "./Description.module.scss";
import classNames from "classnames";
import gsap from "gsap";

export type Props = {
  opened: boolean;
  addClasses?: string[];
} & HTMLProps<HTMLDivElement>;

const Description: FC<Props> = (props) => {
  const tl = useRef<gsap.core.Timeline>();
  const containerRef = useRef<HTMLDivElement>(null);
  const { opened, addClasses } = props;
  const className = classNames(
    [styles["container"], addClasses && [...addClasses]],
    { [styles["closed"]]: !opened }
  );

  useLayoutEffect(() => {
    tl.current = gsap.timeline({ paused: true });
    tl.current.set(containerRef.current, {});
    tl.current.to(containerRef.current, {
      marginTop: 0,
      height: 0,
    });
  }, []);

  useEffect(() => {
    if (!opened) {
      tl.current!.play();
    } else {
      tl.current!.reverse();
    }
  }, [opened]);

  return (
    <div className={className} {...props} ref={containerRef}>
      <span>
        Модуль распознавания товаров и ценников на полках Tevian Shelf
        Recognition оптимизирует работу мерчендайзеров. Позволяет проверить
        отсутствующие товары, оценить долю полки, сравнить выкладку с
        планограммой. Загрузите фотографии полок с товарами или воспользуйтесь
        галерей, чтобы опробовать возможности системы.
      </span>
      <br />
      <br />
      <span>
        Функциональные возможности: детекция фейсингов, распознавание SKU,
        оценка качества выкладки, детекция и распознавание ценников.
      </span>
    </div>
  );
};

export default Description;
