import React, { useState } from "react";
import { Button, Navbar as BSNabvar } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "../Logo/Logo";
import Description from "../Description";

const Navbar = () => {
  // eslint-disable-next-line
  const sessionId = sessionStorage.getItem("sessionId");

  let sessionUrl = null;
  if (sessionId !== null) {
    sessionUrl = `/sessions/${sessionId}`;
  }
  const [descriptionOpened, setDescriptionOpened] = useState(true);

  const toggleDescription = () => {
    setDescriptionOpened((prev) => !prev);
  };

  return (
    <BSNabvar bg="blue2" variant="dark" className="mt-2 container">
      <BSNabvar.Collapse>
        <div className="logo-container">
          <Logo handleClick={toggleDescription} />
          <Description opened={descriptionOpened} />
        </div>
        {sessionUrl !== null ? (
          <Link to={sessionUrl}>
            <Button variant="info">Вернуться в сессию</Button>
          </Link>
        ) : null}
      </BSNabvar.Collapse>
    </BSNabvar>
  );
};

export default Navbar;
