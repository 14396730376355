import { useState } from "react";
import { useDispatch } from "react-redux";
import { UploadImagesToSession } from "../../../../effects/loader";
import { collectDirectoryFiles, sortByName } from "../../utils";
import UniconfApi from "../../../../uniconfApi";

type Status = "awaiting" | "uploading" | "finished" | "error";

interface UploadingSession {
  name: string;
  status: Status;
  sessionId?: number;
}

const useUploadMultipleSessions = (setLoadMode: (mode: string) => void) => {
  const [uploadedFiles, setUploadedFiles] = useState(0);
  const [filesQuantity, setFilesQuantity] = useState(0);
  const [loading, setLoading] = useState(false);
  const [sessions, setSessions] = useState<UploadingSession[]>([]);
  const [error, setError] = useState("");
  const dispatch = useDispatch();

  const closeUploading = () => {
    setError("");
    setLoadMode("");
    setLoading(false);
  };

  const changeSessionStatus = (
    index: number,
    status: Status,
    sessionId?: number
  ) => {
    setSessions((prev) => {
      const prevCopy = [...prev];
      let session = prevCopy[index];
      session = { ...session, status, sessionId };
      prevCopy[index] = session;
      return prevCopy;
    });
  };

  async function handleMultipleSessionsDirectoryEntry() {
    try {
      const dirHandle = await window.showDirectoryPicker();
      setLoadMode("multiple_dir");
      setLoading(true);
      setError("");
      const { sessions, subfolders } = await collectDirectorySubFolders(
        dirHandle
      );
      if (sessions.length === 0) {
        setError("Сессий не найдено!");
        return;
      }
      setSessions(sessions);
      for (let i = 0; i < subfolders.length; i++) {
        const subfolder = subfolders[i];
        const res = await UniconfApi.createSession();
        const uploadSessionId = res.data.id;
        setUploadedFiles(0);
        changeSessionStatus(i, "uploading", uploadSessionId);
        try {
          await handleSingleSessionDirectoryEntry(subfolder, uploadSessionId);
          changeSessionStatus(i, "finished", uploadSessionId);
        } catch (e) {
          changeSessionStatus(i, "error", uploadSessionId);
        }
      }
    } catch (e: any) {
      // code 20 - The user aborted a directory request.
      if (e.code === 20) return;
      setError("Ошибка при загрузке каталога с сессиями!");
    }
  }

  async function handleSingleSessionDirectoryEntry(
    dirHandle: FileSystemDirectoryHandle,
    uploadSessionId: any
  ) {
    try {
      const directoryFiles = await collectDirectoryFiles(dirHandle);
      setFilesQuantity(directoryFiles.length);
      const redirectToSession = false;
      await UploadImagesToSession(
        directoryFiles,
        uploadSessionId,
        dispatch,
        setUploadedFiles,
        redirectToSession
      );
    } catch (e: any) {
      throw new Error(e);
    }
  }

  async function collectDirectorySubFolders(dirHandle: any) {
    try {
      const subfolders = [];
      const sessions: UploadingSession[] = [];
      for await (const entry of dirHandle.values()) {
        if (entry.kind === "directory") {
          const subfolder = await dirHandle.getDirectoryHandle(entry.name, {
            create: false,
          });
          subfolders.push(subfolder);
          sessions.push({
            name: subfolder.name,
            status: "awaiting",
            sessionId: undefined,
          });
        }
      }
      const sortedSessions = sortByName(sessions);
      const sortedSubFolders = sortByName(subfolders);
      return { subfolders: sortedSubFolders, sessions: sortedSessions };
    } catch (e: any) {
      throw new Error(e);
    }
  }

  return {
    sessions,
    handleMultipleSessionsDirectoryEntry,
    loading,
    uploadedFiles,
    filesQuantity,
    error,
    closeUploading,
  };
};

export default useUploadMultipleSessions;
