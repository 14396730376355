import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { closeLoadFailedModal } from "../../../actions/loader";

const LoadFailedModal = () => {
  const showLoadFailedModal = useSelector(
    (state) => state.loaderReducer.showLoadFailedModal
  );
  const dispatch = useDispatch();

  return (
    <Modal show={showLoadFailedModal}>
      <Modal.Header>Ошибка загрузки изображения</Modal.Header>
      <Modal.Body>Повторите снова</Modal.Body>
      <Modal.Footer>
        <Button
          variant="success"
          onClick={() => dispatch(closeLoadFailedModal())}
        >
          ОК
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LoadFailedModal;
