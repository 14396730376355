import React, { FC, HTMLProps } from "react";
import classNames from "classnames";

import styles from "./Spinner.module.scss";

export type Props = { addClasses?: string[] } & HTMLProps<HTMLDivElement>;

const Spinner: FC<Props> = ({ addClasses }) => {
  const className = classNames(
    [styles["container"], addClasses && [...addClasses]],
    {}
  );

  return (
    <div className={styles["wrapper"]}>
      <div className={className}></div>
    </div>
  );
};

export default Spinner;
