import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { closeSession } from "../../../../../actions/session";
import UniconfApi from "../../../../../uniconfApi";

const CloseSessionButton = ({ sessionId }) => {
  const [isFetching, setIsFetching] = useState(false);

  const dispatch = useDispatch();

  const handleClose = async () => {
    setIsFetching(true);
    try {
      await UniconfApi.closeSession(sessionId);
      dispatch(closeSession());
    } catch (e) {
      // TODO: error handling
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <Button
      variant="danger"
      className="mr-1"
      disabled={isFetching}
      onClick={handleClose}
    >
      Закрыть сессию
    </Button>
  );
};

export default CloseSessionButton;
