export const validImageExtensions = {
  jpg: true,
  jpeg: true,
  png: true,
  bmp: true,
};

export const buildNewUrl = (location, queryParams) => {
  const query = Object.entries(queryParams)
    .map(([id, value]) => {
      return `${id}=${value}`;
    })
    .join("&");
  return `${location}?${query}`;
};

export const getLastPhotoIdInSession = (report) => {
  const photo = report.images[report.images.length - 1];
  return photo.id;
};

export const collectDirectoryFiles = async (dirHandle) => {
  try {
    const files = [];
    for await (const entry of dirHandle.values()) {
      if (entry.kind === "file") {
        const file = await entry.getFile();
        const { name } = file;
        const nameArray = name.split(".");
        const ext = nameArray[nameArray.length - 1];
        if (validImageExtensions[ext]) {
          files.push(file);
        }
      }
    }
    const sortedFiles = sortByName(files);
    return sortedFiles;
  } catch (e) {
    throw new Error(e);
  }
};

export const sortByName = (files) => {
  return files.sort((a, b) =>
    a.name.localeCompare(b.name, navigator.languages[0] || navigator.language, {
      numeric: true,
      ignorePunctuation: true,
    })
  );
};
