import { getLastPhotoIdInSession } from "../../utils";

/* TODO:
 * get facing other photo positions
 * get tag other photo positions
 */

const oneOrNull = (items) => (items.length === 1 ? items[0] : null);

const getIdsFromQuery = (query) => {
  const ids = {};
  const queryParams = ["photoId", "facingId", "priceTagId"];
  queryParams.forEach((p) => {
    ids[p] = p in query ? query[p] : null;
  });

  Object.keys(ids).forEach((k) => {
    if (ids[k] !== null && !isNaN(Number.parseInt(ids[k]))) {
      ids[k] = Number(ids[k]);
    }
  });
  return ids;
};

const tagIdFromFacingId = (facingId, report) => {
  const facing = oneOrNull(
    report.facings.filter((f) => Number(f.id) === facingId)
  );
  if (facing === null) {
    return null;
  }
  const tag = oneOrNull(
    report.price_tags.filter((t) => Number(t.id) === facing.price_tag_id)
  );
  return tag === null ? null : tag.id;
};

const facingIdFromTagId = (priceTagId, report) => {
  const facingIdList = report.facings
    .filter((f) => f.price_tag_id === priceTagId)
    .map((f) => f.id);
  return facingIdList;
};

const getIds = (query, report) => {
  let { photoId, facingId, priceTagId } = getIdsFromQuery(query);
  let facingIdList = [];
  if (photoId === null) {
    photoId = getLastPhotoIdInSession(report);
  }
  if (facingId !== null && priceTagId === null) {
    priceTagId = tagIdFromFacingId(facingId, report);
  }
  if (facingId === null && priceTagId !== null) {
    facingIdList = facingIdFromTagId(priceTagId, report);
    facingId = facingIdList.length ? facingIdList[0] : null;
  } else {
    facingIdList.push(facingId);
  }

  return { photoId, facingId, facingIdList, priceTagId };
};

const getPageFromPhotoId = (photoId, photos) => {
  // NOTE: -1 is returned if image does not exist
  const page = photos.findIndex((img, idx, arr) => {
    return Number(img.id) === photoId;
  });
  return page !== -1 ? page + 1 : -1;
};

export { getIds, getPageFromPhotoId };
