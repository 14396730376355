import React, { useEffect } from "react";
import { Container, Image, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { tagCropEffect } from "../../../../../../effects/viewer";
import styles from "../styles.module.css";
import {
  getCropById,
  getDataById,
  renderDescriptionList,
  renderPageOptions,
} from "../utils";
import Spinner from "../../../../Spinner/Spinner";

const pricePromoTypes = {
  PROMO: "Промо",
  REGULAR: "Регулярный",
  LOYALTY: "Лояльность",
  NEW_PRODUCT: "Новинка",
};

const PriceTagReport = ({ photoId, priceTagId }) => {
  const { price_tags: priceTags, images } = useSelector(
    (state) => state.sessionReducer.report
  );
  const priceTagData = getDataById(priceTagId, priceTags);

  const { originalImages, crops } = useSelector(
    (state) => state.sessionReducer
  );
  const imageCrops = photoId in crops ? crops[photoId] : {};
  const priceTagCrop = getCropById(priceTagId, imageCrops, "priceTags");

  const pathname = useSelector((state) => state.router.location.pathname);

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      priceTagCrop === null &&
      priceTagId !== null &&
      photoId in originalImages
    ) {
      dispatch(tagCropEffect(priceTagId, priceTags, photoId, originalImages));
    }
  }, [priceTagCrop, priceTagId, priceTags, originalImages, photoId, dispatch]);

  let tagRenderData = null;
  let priceTagOptionsData = null;
  if (priceTagData !== null) {
    tagRenderData = [
      {
        param: priceTagData.price,
        msg: "Цена",
      },
      {
        param: pricePromoTypes[priceTagData.promo_type],
        msg: "Тип ценника",
      },
      {
        param:
          photoId in priceTagData.positions
            ? priceTagData.positions[photoId].confidence
            : null,
        msg: "Уверенность в обнаружении ценника",
      },
    ];

    const positions = [];
    for (let [k] of Object.entries(priceTagData.positions)) {
      k = parseInt(k);
      if (k !== photoId) {
        positions.push(k);
      }
    }
    if (positions.length !== 0) {
      priceTagOptionsData = {
        itemId: priceTagId,
        itemIdName: "priceTagId",
        itemPrintText: "Ценник",
        positions: positions,
      };
    }
  }

  let tagCropRender = null;
  if (photoId in crops && priceTagId in crops[photoId].priceTags) {
    tagCropRender =
      priceTagCrop !== null ? <Image src={priceTagCrop.src} /> : null;
  } else if (priceTagId !== null) {
    tagCropRender = (
      <Container className={`col-6 text-center ${styles["spinner-container"]}`}>
        <Spinner />
      </Container>
    );
  }
  return tagCropRender !== null ? (
    <div className="mb-5">
      <Container className={`${styles["price-container"]}`}>
        <Row className="col-6 justify-content-center">{tagCropRender}</Row>
      </Container>
      {renderDescriptionList(tagRenderData)}
      {priceTagOptionsData !== null
        ? renderPageOptions(priceTagOptionsData, pathname, images)
        : null}
    </div>
  ) : null;
};

export default PriceTagReport;
