import { routerMiddleware } from "connected-react-router";
import thunkMiddleware from "redux-thunk";
import { applyMiddleware, createStore } from "redux";

import history from "./history";
import reducer from "./reducers";

const store = createStore(
  reducer,
  applyMiddleware(thunkMiddleware, routerMiddleware(history))
);

export default store;
