import { push } from "connected-react-router";
import React, { useState } from "react";
import { Button, Nav, Navbar as BSNavbar, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { dropSession } from "../../../../../actions/session";
import CloseSessionButton from "./CloseSessionButton";
import HelpButton from "./HelpButton";
import ReportDownloadButton from "./ReportDownloadButton";
import Logo from "../../../Logo/Logo";

const Navbar = ({ sessionId }) => {
  const isClosed = useSelector((state) => state.sessionReducer.isClosed);
  const dispatch = useDispatch();

  const handleCreateNewSession = () => {
    // eslint-disable-next-line
    sessionStorage.removeItem("sessionId");
    dispatch(dropSession());
    dispatch(push("/"));
  };

  let buttons = null;
  if (isClosed !== null) {
    buttons = !isClosed ? (
      <>
        <Link to="/">
          <Button className="mr-1" variant="info">
            Загрузить еще фото
          </Button>
        </Link>
        <CloseSessionButton sessionId={sessionId} />
      </>
    ) : (
      <ReportDownloadButton sessionId={sessionId} />
    );
  }

  return (
    <BSNavbar bg="blue2" variant="dark" expand="lg" className="mx-2">
      <BSNavbar.Brand>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Logo />
          <HelpButton />
        </div>
      </BSNavbar.Brand>

      <BSNavbar.Toggle aria-controls="basic-navbar-nav" />
      <BSNavbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto" />
        <Row className="justify-content-center">
          {buttons}
          <Button
            className="mr-1"
            onClick={handleCreateNewSession}
            variant="success"
          >
            Новая сессия
          </Button>
        </Row>
      </BSNavbar.Collapse>
    </BSNavbar>
  );
};

export default Navbar;
