const validateExists = (itemId, items) => {
  return items.filter((item) => item.id === Number(itemId)).length === 1;
};

const validate = (params, validationMap) => {
  const maps = [true, ...Object.entries(validationMap)];
  return maps.reduce((accum, mapping) => {
    const [param, settings] = mapping;
    if (settings.required) {
      accum &= param in params;
      if (param in params) {
        const itemId = params[param];
        accum &= validateExists(itemId, settings.existsIn);
      }
    }
    return accum;
  });
};

export const validateQuery = (query, report) => {
  const validationMap = {
    photoId: {
      existsIn: report.images,
      required: false,
    },
    facingId: {
      existsIn: report.facings,
      required: false,
    },
    priceTagId: {
      existsIn: report.price_tags,
      required: false,
    },
  };

  return validate(query, validationMap);
};
