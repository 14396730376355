import { push } from "connected-react-router";
import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

import { sessionImageLoadEffect } from "../../../../../effects/session";
import { buildNewUrl } from "../../../utils";
import { renderFacings, renderPriceTags } from "./utils";
import Spinner from "../../../Spinner/Spinner";

const SessionImage = ({ photoId, facingId, priceTagId, facingIdList }) => {
  const { sessionId } = useParams();
  const { images, originalImages } = useSelector(
    (state) => state.sessionReducer
  );
  const {
    facings,
    price_tags: priceTags,
    images: photos,
  } = useSelector((state) => state.sessionReducer.report);
  const location = useSelector((state) => state.router.location.pathname);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!(photoId in images) || !(photoId in originalImages)) {
      const angle = photos.filter((p) => p.id === photoId)[0].applied_rotation;
      dispatch(sessionImageLoadEffect(photoId, sessionId, angle));
    }
  }, [photoId, sessionId, dispatch, photos, images, originalImages]);

  const handleSvgClick = () => {
    const query = {
      photoId,
    };
    const url = buildNewUrl(location, query);
    dispatch(push(url));
  };

  const handleFacingClick = (id, e) => {
    e.stopPropagation();
    const query = { photoId, facingId: id };
    const url = buildNewUrl(location, query);
    dispatch(push(url));
  };

  const handleTagClick = (id, e) => {
    e.stopPropagation();
    const query = { photoId, priceTagId: id };
    const url = buildNewUrl(location, query);
    dispatch(push(url));
  };

  const hatched = facings.filter(
    (f, i) =>
      f.categories.length !== 0 &&
      f.categories.brands !== 0 &&
      f.categories.lines !== 0 &&
      f.categories.skus !== 0
  );

  const notHatched = facings.filter(
    (f, i) =>
      f.categories.length === 0 ||
      f.categories.brands === 0 ||
      f.categories.lines === 0 ||
      f.categories.skus === 0
  );

  const selected = facings.filter((f, i) => facingIdList.includes(f.id));

  const renderedHatchedFacings = renderFacings(
    hatched,
    photoId,
    handleFacingClick,
    "#00FF00"
  );
  const renderedNotHatchedFacings = renderFacings(
    notHatched,
    photoId,
    handleFacingClick,
    "#FF0000"
  );

  const renderedSelectedFacing = renderFacings(
    selected,
    photoId,
    handleFacingClick,
    "#0000FF"
  );

  const renderedTags = renderPriceTags(
    priceTags,
    photoId,
    priceTagId,
    handleTagClick
  );

  let view = (
    <Container className="text-center">
      <Spinner />
    </Container>
  );
  if (photoId in images) {
    const { src } = images[photoId];
    const { width, height } = originalImages[photoId];
    view = (
      <div style={{ margin: "0 10px", width: "100%" }}>
        <svg
          width="100%"
          height="85vh"
          viewBox={`0 0 ${width} ${height}`}
          onClick={handleSvgClick}
        >
          <image xlinkHref={src} width={width} height={height} />
          {/* Последний вектор отрисовывается выше всех */}
          {renderedNotHatchedFacings}
          {renderedHatchedFacings}
          {renderedTags}
          {renderedSelectedFacing}
        </svg>
      </div>
    );
  }

  return view;
};

export default SessionImage;
