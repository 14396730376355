import React from "react";
import { Alert } from "react-bootstrap";

const InvalidUrlParamBanner = () => {
  return (
    <Alert variant="danger">
      <Alert.Heading>Введена неверная ссылка</Alert.Heading>
      <div>Допущена ошибка в ссылке или она больше не действительна</div>
    </Alert>
  );
};

export default InvalidUrlParamBanner;
