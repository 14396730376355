import React from "react";
import { Container } from "react-bootstrap";
import FacingReport from "./FacingReport/component";
import PriceTagReport from "./PriceTagReport/component";

const SessionReport = ({ photoId, facingId, priceTagId }) => {
  return (
    <Container className="mt-5">
      <FacingReport photoId={photoId} facingId={facingId} />
      <PriceTagReport photoId={photoId} priceTagId={priceTagId} />
    </Container>
  );
};

export default SessionReport;
