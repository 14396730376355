import { handleActions } from "redux-actions";

const defaultState = {
  isClosed: null,
  isIniting: true,
  isImageLoading: false,
  images: {},
  originalImages: {},
  crops: {},
  skuImages: {},
  report: null,
};

export default handleActions(
  {
    SET_SESSION_DATA: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    INIT_SESSION: (state, action) => {
      const { report } = action.payload;
      return {
        ...state,
        report,
      };
    },
    DROP_SESSION: (state, action) => {
      const newState = {
        ...defaultState,
        images: {},
        originalImages: {},
        facingCrops: {},
        priceTagCrops: {},
        skuImages: {},
      };
      return newState;
    },
    CLOSE_SESSION: (state, action) => {
      return {
        ...state,
        isClosed: true,
      };
    },
    ADD_IMAGE_TO_SESSION: (state, action) => {
      const { imgId, viewImg, originalImg } = action.payload;
      const images = Object.assign({}, state.images);
      const originalImages = Object.assign({}, state.originalImages);
      images[imgId] = viewImg;
      originalImages[imgId] = originalImg;
      return {
        ...state,
        images,
        originalImages,
      };
    },
    UPDATE_SESSION_REPORT: (state, action) => {
      const { report } = action.payload;
      return {
        ...state,
        report,
      };
    },
    CACHE_FACING_CROP: (state, action) => {
      const { photoId, facingId, crop } = action.payload;
      const newCrops = Object.assign({}, state.crops);
      if (!(photoId in newCrops)) {
        newCrops[photoId] = {
          facings: {},
          priceTags: {},
        };
      }
      newCrops[photoId].facings[facingId] = crop;
      return {
        ...state,
        crops: newCrops,
      };
    },
    CACHE_TAG_CROP: (state, action) => {
      const { photoId, priceTagId, crop } = action.payload;
      const newCrops = Object.assign({}, state.crops);
      if (!(photoId in newCrops)) {
        newCrops[photoId] = {
          facings: {},
          priceTags: {},
        };
      }
      newCrops[photoId].priceTags[priceTagId] = crop;
      return {
        ...state,
        crops: newCrops,
      };
    },
    CACHE_SKU: (state, action) => {
      const { sku, image } = action.payload;
      const newSkus = Object.assign({}, state.skuImages);
      newSkus[sku] = image;
      return {
        ...state,
        skuImages: newSkus,
      };
    },
    SET_IS_IMAGE_LOADING: (state, action) => {
      const { isImageLoading } = action.payload;
      return {
        ...state,
        isImageLoading,
      };
    },
  },
  Object.assign({}, defaultState)
);
