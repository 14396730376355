import { ConnectedRouter } from "connected-react-router";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import App from "./components/App/component";
import history from "./history";
import "./index.scss";
import store from "./store";

const { SENTRY_DSN, SENTRY_ENVIRONMENT, SENTRY_TRACES_RATE } = window;

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: +SENTRY_TRACES_RATE,
  environment: SENTRY_ENVIRONMENT,
});

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
);
