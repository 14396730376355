import React from "react";
import { IS_EMPTY_THRESHOLD, IS_DEEP_THRESHOLD } from "../../../../../config";

export const renderFacings = (facings, photoId, handler, color) => {
  const filteredFacings = facings.filter((f) => photoId in f.positions);
  return filteredFacings.map((f, i) => {
    let points = "";
    const rectangle = f.positions[photoId].rectangle;
    rectangle.forEach((point) => {
      points += `${point[0]},${point[1]} `;
    });
    let currentColor = color;
    let isEmpty = f.is_empty_confidence > IS_EMPTY_THRESHOLD;
    let isDeep = !isEmpty && f.is_deep_placed_confidence > IS_DEEP_THRESHOLD;

    // rectangle - список координат bbox вида (x, y)
    // список начинается с координат верхней левой точки и продолжается по часовой стрелке
    // 0---1
    // |   |
    // 3---2
    // При выборе точек 1 число - номер угла. Второе 0 - X, 1 - Y
    // rectangle[0][0]- Х координата верхней левой точки
    // rectangle[0][1]- У координата верхней левой точки

    const maxLeft = Math.min(rectangle[0][0], rectangle[3][0]); // крайняя левая точка X параллелограмма
    const maxRight = Math.max(rectangle[1][0], rectangle[2][0]); // крайняя правая точка X параллелограмма
    const diffLeft = Math.abs((rectangle[0][0] - rectangle[3][0]) / 2); // разница левых координат Х
    const diffRight = Math.abs((rectangle[1][0] - rectangle[2][0]) / 2); // разница правых координат Х
    return (
      <g key={f.id}>
        {isEmpty ? (
          <>
            <line
              x1={rectangle[0][0]}
              y1={rectangle[0][1]}
              x2={rectangle[2][0]}
              y2={rectangle[2][1]}
              strokeWidth="0.5%"
              stroke={currentColor}
            />
            <line
              x1={rectangle[1][0]}
              y1={rectangle[1][1]}
              x2={rectangle[3][0]}
              y2={rectangle[3][1]}
              strokeWidth="0.5%"
              stroke={currentColor}
            />
          </>
        ) : null}
        {isDeep ? (
          <>
            <line
              x1={maxLeft + diffLeft + 1} // 1 - скрыть прямоугольный конец линии
              y1={rectangle[3][1] - (rectangle[2][1] - rectangle[0][1]) / 2} // vertical center
              // совпадают с углами прямоугольника
              x2={rectangle[2][0]}
              y2={rectangle[2][1]}
              strokeWidth="0.5%"
              stroke={currentColor}
            />
            <line
              x1={maxRight - diffRight + 1}
              y1={rectangle[1][1] + (rectangle[3][1] - rectangle[1][1]) / 2} // vertical center
              // совпадают с углами прямоугольника
              x2={rectangle[3][0]}
              y2={rectangle[3][1]}
              strokeWidth="0.5%"
              stroke={currentColor}
            />
          </>
        ) : null}
        <polygon
          key={f.id}
          id={`rect${f.id}`}
          onClick={(e) => handler(f.id, e)}
          fill={currentColor}
          points={points}
          strokeWidth="0.5%"
          stroke={currentColor}
          fillOpacity={0}
        />
      </g>
    );
  });
};

export const renderPriceTags = (priceTags, photoId, selectedTagId, handler) => {
  return priceTags
    .filter((tag) => photoId in tag.positions)
    .map((tag) => {
      const color = selectedTagId === tag.id ? "#0000FF" : "#00FF00";
      const bboxData = tag.positions[photoId].bounding_box;
      const bbox = {
        x: bboxData[0],
        y: bboxData[1],
        width: bboxData[2],
        height: bboxData[3],
      };
      return (
        <rect
          key={tag.id}
          {...bbox}
          onClick={(e) => handler(tag.id, e)}
          strokeWidth="0.5%"
          stroke={color}
          fill={color}
          fillOpacity={selectedTagId === tag.id ? "0.5" : "0"}
        />
      );
    });
};
