const setSessionData = (isClosed) => {
  return {
    type: "SET_SESSION_DATA",
    payload: { isClosed },
  };
};

const dropSession = () => {
  return {
    type: "DROP_SESSION",
  };
};

const initSession = (report) => {
  return {
    type: "INIT_SESSION",
    payload: { report },
  };
};

const addImageToSession = (imgId, viewImg, originalImg) => {
  return {
    type: "ADD_IMAGE_TO_SESSION",
    payload: { imgId, viewImg, originalImg },
  };
};

const updateSessionReport = (report) => {
  return {
    type: "UPDATE_SESSION_REPORT",
    payload: { report },
  };
};

const setIsImageLoading = (isImageLoading) => {
  return {
    type: "SET_IS_IMAGE_LOADING",
    payload: { isImageLoading },
  };
};
const cacheFacingCrop = (photoId, facingId, crop) => {
  return {
    type: "CACHE_FACING_CROP",
    payload: { photoId, facingId, crop },
  };
};

const cacheTagCrop = (photoId, priceTagId, crop) => {
  return {
    type: "CACHE_TAG_CROP",
    payload: { photoId, priceTagId, crop },
  };
};

const cacheSku = (sku, image) => {
  return {
    type: "CACHE_SKU",
    payload: { sku, image },
  };
};

const closeSession = () => {
  return {
    type: "CLOSE_SESSION",
  };
};

export {
  setSessionData,
  dropSession,
  initSession,
  closeSession,
  addImageToSession,
  updateSessionReport,
  setIsImageLoading,
  cacheFacingCrop,
  cacheTagCrop,
  cacheSku,
};
