import React from "react";
import { ProgressBar, Button, Alert } from "react-bootstrap";
import useUploadSingleSession from "./useUploadSingleSession";
import Spinner from "../../Spinner/Spinner";

const UploadSingleSession = ({ loadMode, setLoadMode }) => {
  const {
    handleSingleSessionDirectoryEntry,
    loading,
    filesQuantity,
    uploadedFiles,
    error,
    closeUploading,
  } = useUploadSingleSession(setLoadMode);
  const sessionId = sessionStorage.getItem("sessionId");
  const buttonTitle = sessionId
    ? "Загрузить несколько фото в эту сессию"
    : "Загрузить одну сессию";
  const visible = !loadMode || loadMode === "single_dir";

  if (!visible) return null;
  if (error) {
    return (
      <Alert
        className="m-0"
        variant={"danger"}
        onClose={closeUploading}
        dismissible
      >
        {error}
      </Alert>
    );
  }
  if (loading) {
    return (
      <div>
        <Spinner />
        {filesQuantity > 0 && (
          <>
            <ProgressBar
              now={(uploadedFiles / filesQuantity) * 100}
              label={`${((uploadedFiles / filesQuantity) * 100).toFixed(0)}%`}
            />
            <p>
              Загружено {uploadedFiles} из {filesQuantity} картинок
            </p>
          </>
        )}
      </div>
    );
  }
  return (
    <Button
      className="mt-1"
      variant="blue"
      onClick={handleSingleSessionDirectoryEntry}
    >
      {buttonTitle}
    </Button>
  );
};

export default UploadSingleSession;
