import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { initSessionEffect } from "../../../../effects/viewer";
import InvalidUrlParamBanner from "./InvalidUrlParamBanner";
import { Navbar } from "./Navbar";
import { SessionImage } from "./SessionImage";
import SessionPagination from "./SessionPagination";
import { SessionReport } from "./SessionReport";
import { getIds, getPageFromPhotoId } from "./utils";
import { validateQuery } from "./validator";
import styles from "./styles.module.css";
import Spinner from "../../Spinner/Spinner";
import BottomSection from "../../BottomSection";

const Viewer = ({ sessionId }) => {
  const { report } = useSelector((state) => state.sessionReducer);
  const query = useSelector((state) => state.router.location.query);
  const dispatch = useDispatch();

  useEffect(() => {
    if (report === null) {
      dispatch(initSessionEffect(sessionId));
    }
  }, [report, dispatch, sessionId]);

  let view = (
    <Container>
      <Col>
        <Row>Загрузка сессии. Пожалуйста, подождите</Row>
        <Row>
          <Spinner />
        </Row>
      </Col>
    </Container>
  );
  if (report !== null) {
    const totalPages = report.images.length;

    if (validateQuery(query, report)) {
      const { photoId, facingId, facingIdList, priceTagId } = getIds(
        query,
        report
      );

      const page = getPageFromPhotoId(photoId, report.images);
      if (page === -1) {
        view = <InvalidUrlParamBanner />;
      } else {
        view = (
          <Container fluid className="min-vh-100">
            <Row>
              <Col className="col-12 col-sm-12 col-md-12 col-lg-4 mt-1">
                <Row className="justify-content-center">
                  <SessionPagination page={page} total={totalPages} />
                </Row>
                <Row>
                  <SessionImage
                    photoId={photoId}
                    facingId={facingId}
                    priceTagId={priceTagId}
                    facingIdList={facingIdList}
                  />
                </Row>
              </Col>
              <Col className="col-12 col-sm-12 col-md-12 col-lg-8">
                <SessionReport
                  photoId={photoId}
                  facingId={facingId}
                  priceTagId={priceTagId}
                />

                {!facingId && !priceTagId && (
                  <div className={styles["click-me"]}>
                    <h2>Кликните по товару или ценнику</h2>
                  </div>
                )}
              </Col>
            </Row>
          </Container>
        );
      }
    } else {
      view = <InvalidUrlParamBanner />;
    }
  }

  return (
    <>
      <Navbar sessionId={sessionId} />
      {view}
      <BottomSection />
    </>
  );
};

export default Viewer;
