import React, { useEffect } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router";

import "../../../node_modules/bootstrap/dist/css/bootstrap.css";
import { Loader } from "./Loader";
import Session from "./Session/component";
import BottomSection from "./BottomSection/BottomSection";

const DefaultRoute = () => {
  // eslint-disable-next-line
  const sessionId = sessionStorage.getItem("sessionId");
  if (sessionId !== null) {
    return <Redirect to={`/sessions/${sessionId}`} />;
  }
  return <Redirect to="/" />;
};

function App() {
  useEffect(() => {
    window.ym(window.YANDEX_METRIC_ID, "hit", window.location.href);
  }, []);
  return (
    <>
      <Switch>
        <Route exact path="/" component={Loader} />
        <Route exact path="/sessions/:sessionId" component={Session} />
        <Route component={DefaultRoute} />
      </Switch>
    </>
  );
}

export default withRouter(App);
