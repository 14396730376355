import React, { FC } from "react";

import { ReactComponent as Tevian } from "./tevian.svg";
import { ReactComponent as NNM } from "./nnm.svg";
import classNames from "classnames";
import styles from "./Logo.module.scss";
import { ReactComponent as InfoIcon } from "./info.svg";

export type Props = { handleClick?: () => void; addClasses?: string[] };

const Logo: FC<Props> = ({ handleClick, addClasses }) => {
  const className = classNames(
    [styles["container"], addClasses && [...addClasses]],
    {}
  );
  return (
    <div className={className}>
      <div className={styles["logo-container"]}>
        <a href="https://tevian.ai" target="_blank" rel="noreferrer">
          <Tevian className={styles["logo"]} />
        </a>
        <a href="https://noname.software" target="_blank" rel="noreferrer">
          <NNM className={styles["logo-nnm"]} />
        </a>
        {handleClick && (
          <InfoIcon onClick={handleClick} className={styles["info-icon"]} />
        )}
      </div>
      <span>
        Распознавание товаров <br />и ценников на полках
      </span>
    </div>
  );
};

export default Logo;
