import React, { useEffect } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  facingCropEffect,
  skuLoadEffect,
} from "../../../../../../effects/viewer";
import styles from "../styles.module.css";
import {
  getCropById,
  getDataById,
  getMostConfidentItem,
  renderDescriptionList,
  renderPageOptions,
} from "../utils";
import question from "../../../../../../assets/imgs/question.svg";
import Spinner from "../../../../Spinner/Spinner";

const FacingReport = ({ photoId, facingId }) => {
  const { facings, images } = useSelector(
    (state) => state.sessionReducer.report
  );
  const facingData = getDataById(facingId, facings);

  const { originalImages, crops, skuImages } = useSelector(
    (state) => state.sessionReducer
  );
  const imageCrops = photoId in crops ? crops[photoId] : {};
  const facingCrop = getCropById(facingId, imageCrops, "facings");

  const pathname = useSelector((state) => state.router.location.pathname);

  const dispatch = useDispatch();
  let sku = null;
  let skuImage = null;

  useEffect(() => {
    if (facingCrop === null && facingId !== null && photoId in originalImages) {
      dispatch(facingCropEffect(facingId, facings, photoId, originalImages));
    }
  }, [facingCrop, facingId, facings, originalImages, photoId, dispatch]);
  let facingRenderData = null;
  let facingOptionsData = null;
  if (facingData !== null) {
    sku = getMostConfidentItem(facingData.skus);
    if (sku !== null && sku.name in skuImages) {
      skuImage = skuImages[sku.name];
    }
    facingRenderData = [
      {
        param: facingData.is_empty_confidence.toFixed(2),
        msg: "Уверенность, что фейсинг пуст",
      },
      {
        param: facingData.is_deep_placed_confidence.toFixed(2),
        msg: "Уверенность, что товар в глубине полки",
      },
      {
        param: getMostConfidentItem(facingData.categories),
        msg: "Категория",
      },
      {
        param: getMostConfidentItem(facingData.brands),
        msg: "Бренд",
      },
      {
        param: getMostConfidentItem(facingData.lines),
        msg: "Наименование",
      },
      {
        param: sku !== null ? sku.name : null,
        msg: "SKU",
      },
    ];

    const positions = [];
    for (let [k] of Object.entries(facingData.positions)) {
      k = parseInt(k);
      if (k !== photoId) {
        positions.push(k);
      }
    }

    if (positions.length !== 0) {
      facingOptionsData = {
        itemId: facingId,
        itemIdName: "facingId",
        itemPrintText: "Фейсинг",
        positions: positions,
      };
    }
  }

  useEffect(() => {
    if (skuImage === null && sku !== null) {
      dispatch(skuLoadEffect(sku));
    }
  }, [skuImage, sku, dispatch]);

  let facingCropRender = null;
  if (photoId in crops && facingId in crops[photoId].facings) {
    facingCropRender =
      facingCrop !== null ? <Image src={facingCrop.src} /> : null;
  } else if (facingId !== null) {
    facingCropRender = (
      <Container className={`text-center ${styles["spinner-container"]}`}>
        <Spinner />
      </Container>
    );
  }

  let skuCropRender = null;
  if (skuImage !== null) {
    skuCropRender = (
      <Image fluid style={{ maxHeight: "200px" }} src={skuImage.src} />
    );
  } else if (sku !== null) {
    skuCropRender = (
      <Container className={`text-center ${styles["spinner-container"]}`}>
        <Spinner />
      </Container>
    );
  }

  return facingCropRender !== null ? (
    <div className="mb-5">
      <div className={styles["container"]}>
        <div>
          <h3 className={styles["column-title"]}>Товар на фото</h3>
          <div className={styles["img-container"]}>{facingCropRender}</div>
        </div>

        <div>
          <h3 className={styles["column-title"]}>Сопоставление с базой</h3>
          <div>
            {skuCropRender ? (
              skuCropRender
            ) : (
              <>
                <h4>Товара нет в демо-базе</h4>
                <div className={styles["img-container"]}>
                  <img
                    src={question}
                    alt="not found"
                    style={{ width: "100%" }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {renderDescriptionList(facingRenderData)}
      {facingOptionsData !== null
        ? renderPageOptions(facingOptionsData, pathname, images)
        : null}
    </div>
  ) : null;
};

export default FacingReport;
