import { useState } from "react";
import { useDispatch } from "react-redux";
import UniconfApi from "../../../../uniconfApi";
import { UploadImagesToSession } from "../../../../effects/loader";

const useDropzone = (setLoadMode: (mode: string) => void) => {
  const sessionId = sessionStorage.getItem("sessionId");
  const dispatch = useDispatch();
  const [uploadedFiles, setUploadedFiles] = useState(0);
  const [filesQuantity, setFilesQuantity] = useState(0);
  const [loading, setLoading] = useState(false);

  const handleDrop = async (files: any) => {
    try {
      setLoading(true);
      setLoadMode("multiple_files");
      setFilesQuantity(files.length);
      let uploadSessionId = sessionId;
      if (sessionId === null) {
        const res = await UniconfApi.createSession();
        sessionStorage.setItem("sessionId", res.data.id);
        uploadSessionId = res.data.id;
      }
      const redirectToSession = true;
      await UploadImagesToSession(
        files,
        uploadSessionId,
        dispatch,
        setUploadedFiles,
        redirectToSession
      );
      setLoadMode("");
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return { handleDrop, loading, filesQuantity, uploadedFiles };
};

export default useDropzone;
