import React from "react";
import styled from "styled-components";
import usePresetImgs from "./usePresetImgs";
import { Alert } from "react-bootstrap";
import Spinner from "../../Spinner/Spinner";
import Img1 from "../../../../assets/imgs/preset/1.jpg";
import Img2 from "../../../../assets/imgs/preset/2.jpg";
import Img3 from "../../../../assets/imgs/preset/3.jpg";

const Container = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 5px;
  padding: 5px;
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;

const Item = styled.div`
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.5s ease;
  &:hover {
    transform: translateY(-10px);
  }
`;

const PresetImgs = ({ loadMode, setLoadMode }) => {
  const { error, closeUploading, handleClick, loading } =
    usePresetImgs(setLoadMode);
  const visible = !loadMode || loadMode === "preset_imgs";
  if (!visible) return null;

  if (error) {
    return (
      <Alert
        className="m-0"
        variant={"danger"}
        onClose={closeUploading}
        dismissible
      >
        {error}
      </Alert>
    );
  }

  if (loading) {
    return <Spinner />;
  }

  return (
    <div style={{ marginTop: "1em" }}>
      <h4>Либо загрузите один из демонстрационных примеров:</h4>
      <Container>
        <Item onClick={() => handleClick(Img1)}>
          <img src={Img1} style={{ width: "100%" }} alt="" />
        </Item>
        <Item onClick={() => handleClick(Img2)}>
          <img src={Img2} style={{ width: "100%" }} alt="" />
        </Item>
        <Item onClick={() => handleClick(Img3)}>
          <img src={Img3} style={{ width: "100%" }} alt="" />
        </Item>
      </Container>
    </div>
  );
};

export default PresetImgs;
