import { useState } from "react";
import { useDispatch } from "react-redux";
import { UploadImagesToSession } from "../../../../effects/loader";
import UniconfApi from "../../../../uniconfApi";

const usePresetImgs = (setLoadMode: (mode: string) => void) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  let file: null | File = null;

  const sessionId = sessionStorage.getItem("sessionId");
  const dispatch = useDispatch();

  const closeUploading = () => {
    setError("");
    setLoadMode("");
    setLoading(false);
  };

  const handleClick = async (imageSrc: string) => {
    try {
      setLoadMode("preset_imgs");
      setLoading(true);
      let uploadSessionId = sessionId;
      if (sessionId === null) {
        const res = await UniconfApi.createSession();
        sessionStorage.setItem("sessionId", res.data.id);
        uploadSessionId = res.data.id;
      }

      // конвертировать ссылку на изображение в изображение
      await fetch(imageSrc).then(async (res) => {
        const contentType = res.headers.get("content-type") || undefined;
        const blob = await res.blob();
        file = new File([blob], "image", { type: contentType });
      });

      await UploadImagesToSession(
        [file],
        uploadSessionId,
        dispatch,
        null,
        true
      );
    } catch (e) {
      setError("Произошла ошибка при загрузке фото!");
    } finally {
      setLoading(false);
    }
  };
  return { loading, handleClick, error, closeUploading };
};

export default usePresetImgs;
