import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getPageFromPhotoId } from "../utils";

import styles from "./styles.module.css";

const getMostConfidentItem = (array) => {
  if (array.length === 0) {
    return null;
  }
  // API гарантирует, что первый по порядку элемент будет соответствовать SKU с наибольшей уверенностью
  return array[0];
};

const isObjectType = (obj) => {
  return obj.constructor === {}.constructor;
};

const getDataById = (id, data) => {
  if (id !== null) {
    const filteredData = data.filter((item) => item.id === id);
    return filteredData.length === 1 ? filteredData[0] : null;
  }
  return null;
};

const getCropById = (id, crops, key) => {
  if (!(key in crops)) {
    return null;
  }
  const items = crops[key];
  return id in items ? items[id] : null;
};

const renderDescriptionList = (items) => {
  if (items === null) {
    return null;
  }
  return items.map((item) => {
    if (!("param" in item)) {
      return null;
    }
    if (item.param === null) {
      return null;
    }

    let body = item.param;
    if (isObjectType(item.param)) {
      body = `${item.param.name} (${item.param.confidence.toFixed(2)})`;
    } else {
      const num = parseFloat(item.param);
      if (!Number.isNaN(num) && !Number.isInteger(num)) {
        body = num.toFixed(2);
      }
    }
    return (
      <Container key={item.id} className={styles["row-wrapper"]}>
        <Row>
          <Col className={`${styles["report-text"]} col-4`}>{item.msg}</Col>
          <Col className={styles["report-text"]}>{body}</Col>
        </Row>
      </Container>
    );
  });
};

const renderPageOptions = (itemData, baseUrl, photos) => {
  const { itemId, itemIdName, itemPrintText, positions } = itemData;
  return (
    <Container>
      <Row>
        <Col>{`${itemPrintText} также присутствует на`}</Col>
        <Col>
          <Row>
            {positions.map((id) => {
              const url = `${baseUrl}?photoId=${id}&${itemIdName}=${itemId}`;
              return (
                <Col key={id}>
                  <Link to={url}>{`фото ${getPageFromPhotoId(
                    id,
                    photos
                  )}`}</Link>
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export {
  getMostConfidentItem,
  getDataById,
  getCropById,
  renderDescriptionList,
  renderPageOptions,
};
