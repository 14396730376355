import { push } from "connected-react-router";
import {
  addImageToSession,
  setSessionData,
  updateSessionReport,
} from "../actions/session";
import { buildNewUrl } from "../components/App/utils";
import UniconfApi from "../uniconfApi";
import { convertBlobToImage, downscaleImage, rotateImage } from "./common";

export const UploadImagesToSession = async (
  files,
  sessionId,
  dispatch,
  countUploadedFiles,
  redirectToSession
) => {
  let resp;
  let photoId;
  let newReport;
  for await (let file of files) {
    try {
      resp = await UniconfApi.postImage(file, sessionId);
      photoId = resp.data.id;
      resp = await UniconfApi.getSessionReport(sessionId);
      newReport = resp.data;
      const image = await convertBlobToImage(file);
      const angle = newReport.images.filter((p) => p.id === photoId)[0]
        .applied_rotation;
      const cropImage = await rotateImage(image, angle);
      const viewImage = await downscaleImage(cropImage);
      dispatch(addImageToSession(photoId, viewImage, cropImage));
      dispatch(updateSessionReport(newReport));
      if (countUploadedFiles) {
        countUploadedFiles((prev) => (prev += 1));
      }
    } catch (e) {
      throw new Error(e);
    }
  }
  if (!redirectToSession) return;
  dispatch(setSessionData(sessionId, false));
  const query = { photoId };
  const location = `/sessions/${sessionId}`;
  const url = buildNewUrl(location, query);
  dispatch(push(url));
};
