import { push } from "connected-react-router";
import React from "react";
import { Pagination } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { buildNewUrl } from "../../utils";

const SessionPagination = ({ page, total }) => {
  const images = useSelector((state) => state.sessionReducer.report.images);
  const location = useSelector((state) => state.router.location.pathname);
  const dispatch = useDispatch();

  const handlePaging = (newPage) => {
    const query = { photoId: images[newPage - 1].id };
    const url = buildNewUrl(location, query);
    dispatch(push(url));
  };

  return (
    <Pagination>
      <Pagination.Prev
        onClick={() => handlePaging(page - 1)}
        disabled={page === 1}
      />
      <Pagination.Item disabled>{`${page} / ${total}`}</Pagination.Item>
      <Pagination.Next
        onClick={() => handlePaging(page + 1)}
        disabled={page === total}
      />
    </Pagination>
  );
};

export default SessionPagination;
