import axios from "axios";

import API_URL from "./config";
import { loadImage } from "./effects/common";

const createSession = () => {
  return axios.post(`${API_URL}/sessions`);
};

const postImage = (img, sessionId) => {
  return axios.post(`${API_URL}/sessions/${sessionId}/photos`, img);
};

const getSession = (sessionId) => {
  return axios.get(`${API_URL}/sessions/${sessionId}`);
};

const getSessionReport = (sessionId) => {
  return axios.get(`${API_URL}/sessions/${sessionId}/report`);
};

const getImage = (sessionId, photoId) => {
  return new Promise((resolve) => {
    const src = `${API_URL}/sessions/${sessionId}/photos/${photoId}`;
    loadImage(src).then((img) => resolve(img));
  });
};

const closeSession = (sessionId) => {
  return axios.post(`${API_URL}/sessions/${sessionId}/close`);
};

const getSku = (sku) => {
  const url = `/skus/${sku}`;
  return loadImage(url);
};

export default {
  createSession,
  closeSession,
  postImage,
  getSession,
  getSessionReport,
  getImage,
  getSku,
};
