import { handleActions } from "redux-actions";

const defaultState = {
  isLoading: false,
  showLoadFailedModal: false,
};

export default handleActions(
  {
    SET_IS_LOADING: (state, action) => {
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };
    },
    LOAD_FAILED: (state, _) => {
      return {
        ...state,
        showLoadFailedModal: true,
      };
    },
    CLOSE_LOAD_FAILED_MODAL: (state, _) => {
      return {
        ...state,
        showLoadFailedModal: false,
      };
    },
  },
  defaultState
);
