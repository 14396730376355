import {
  addImageToSession,
  setIsImageLoading,
  setSessionData,
} from "../actions/session";
import UniconfApi from "../uniconfApi";
import { downscaleImage, rotateImage } from "./common";

export const sessionImageLoadEffect = (photoId, sessionId, angle) => {
  return async (dispatch) => {
    dispatch(setIsImageLoading(true));

    const image = await UniconfApi.getImage(sessionId, photoId);
    const rotatedImage = await rotateImage(image, angle);
    const scaledImage = await downscaleImage(rotatedImage);
    dispatch(addImageToSession(photoId, scaledImage, rotatedImage));

    dispatch(setIsImageLoading(false));
  };
};

export const sessionVerifyClosedEffect = (sessionId) => {
  return async (dispatch) => {
    UniconfApi.getSession(sessionId).then((res) => {
      dispatch(setSessionData(res.data.closed));
    });
  };
};
