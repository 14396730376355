import React, { useRef, useState } from "react";
import { Button, Overlay, Tooltip } from "react-bootstrap";
import style from "./styles.module.css";

const HelpButton = () => {
  const [showAbout, setShowAbout] = useState(false);
  const btnRef = useRef();

  const handleClick = () => {
    setShowAbout(!showAbout);
  };

  return (
    <>
      <Button
        ref={btnRef}
        className={style["help-button"]}
        onClick={handleClick}
      >
        Справка
      </Button>
      <Overlay target={btnRef.current} show={showAbout} placement="bottom">
        {(props) => (
          <Tooltip {...props}>
            <h3>Распознавание товаров и пустот</h3>
            <p>
              Система находит полки на центральном стеллаже фотографии,
              определяет границы фейсингов. Поддерживаемые в демо-базе и
              распознавшиеся товары отмечаются красной заливкой. При клике по
              такому фейсингу отображается информация о товаре и изображение из
              базы. Пустые фейсинги отмечаются крестом.
            </p>
            <h3>Распознавание ценников</h3>
            <p>
              Система локализует ценники на фото, для них распознаётся цена и
              акционность. Ценники сопоставляются с фейсингами.
            </p>
            <h3>Стенд и демо-база</h3>
            <p>
              На этом стенде проводится распознавание среди ~250 российских
              кондитерских изделий. При загрузке новых фотографий можно выбрать
              один из предложенных примеров или любую другую фотографию стеллажа
              в схожем ракурсе. Даже если на фото не будет поддерживаемых
              товаров, ценники, пустоты и границы товаров будут распознаваться.
            </p>
          </Tooltip>
        )}
      </Overlay>
    </>
  );
};

export default HelpButton;
