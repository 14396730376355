const setIsLoading = (isLoading) => {
  return {
    type: "SET_IS_LOADING",
    payload: {
      isLoading: isLoading,
    },
  };
};

const loadFailed = () => {
  return {
    type: "LOAD_FAILED",
    payload: {
      showLoadFailedModal: true,
    },
  };
};

const closeLoadFailedModal = () => {
  return {
    type: "CLOSE_LOAD_FAILED_MODAL",
    payload: {
      showLoadFailedModal: false,
    },
  };
};

export { setIsLoading, loadFailed, closeLoadFailedModal };
