import React from "react";
import { ProgressBar } from "react-bootstrap";
import Spinner from "../../Spinner/Spinner";
import ReactDropzone from "react-dropzone";
import "../style.scss";
import useDropzone from "./useDropzone";
import { ReactComponent as FileIcon } from "./file-icon.svg";

const Dropzone = ({ loadMode, setLoadMode }) => {
  const { handleDrop, loading, filesQuantity, uploadedFiles } =
    useDropzone(setLoadMode);

  const visible = !loadMode || loadMode === "multiple_files";

  if (!visible) return null;
  return !loading ? (
    <div style={{ cursor: "pointer" }}>
      <ReactDropzone onDrop={handleDrop} accept="image/*">
        {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
          <div className="dropzone-drop-area" {...getRootProps()}>
            <input {...getInputProps()} />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <FileIcon className="dropzone-icon" />
              <span>
                {!isDragReject ? (
                  isDragActive ? (
                    "Отпускайте"
                  ) : (
                    "Выберите или перетащите файлы"
                  )
                ) : (
                  <div className="text-danger">Неверное расширение файла</div>
                )}
              </span>
            </div>
          </div>
        )}
      </ReactDropzone>
    </div>
  ) : (
    <div>
      <Spinner />
      {filesQuantity > 0 && (
        <>
          <ProgressBar
            now={(uploadedFiles / filesQuantity) * 100}
            label={`${((uploadedFiles / filesQuantity) * 100).toFixed(0)}%`}
          />
          <p>
            Загружено {uploadedFiles} из {filesQuantity} картинок
          </p>
        </>
      )}
    </div>
  );
};

export default Dropzone;
