import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";

import history from "../history";
import loaderReducer from "./loader";
import sessionReducer from "./session";

export default combineReducers({
  sessionReducer,
  loaderReducer,
  router: connectRouter(history),
});
