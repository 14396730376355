import React, { useState } from "react";
import { Container } from "react-bootstrap";
import Dropzone from "./Dropzone";
import PresetImgs from "./PresetImgs/PresetImgs";
import LoadFailedModal from "./LoadFailedModal";
import Navbar from "./Navbar";
import UploadSingleSession from "./UploadSingleSession";
import UploadMultipleSessions from "./UploadMultipleSessions";
import "./style.scss";
import BottomSection from "../BottomSection";

const Loader = () => {
  const [loadMode, setLoadMode] = useState("");

  return (
    <>
      <Navbar />
      <Container className="text-center mt-2 min-vh-100">
        <Dropzone loadMode={loadMode} setLoadMode={setLoadMode} />
        {window.showDirectoryPicker && (
          <>
            <UploadSingleSession
              loadMode={loadMode}
              setLoadMode={setLoadMode}
            />
            <UploadMultipleSessions
              loadMode={loadMode}
              setLoadMode={setLoadMode}
            />
          </>
        )}
        <PresetImgs loadMode={loadMode} setLoadMode={setLoadMode} />
      </Container>
      <BottomSection />
      <LoadFailedModal />
    </>
  );
};

export default Loader;
