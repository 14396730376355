import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { Redirect } from "react-router-dom";
import { sessionVerifyClosedEffect } from "../../../effects/session";
import { Viewer } from "./Viewer";

function Session() {
  const dispatch = useDispatch();
  let { sessionId } = useParams();
  sessionId = Number(sessionId);

  useEffect(() => {
    dispatch(sessionVerifyClosedEffect(sessionId));
  }, [sessionId, dispatch]);

  if (isNaN(sessionId)) {
    // eslint-disable-next-line
    sessionStorage.removeItem("sessionId");

    return <Redirect to="/" />;
  }

  // eslint-disable-next-line
  sessionStorage.setItem("sessionId", sessionId);

  return <Viewer sessionId={sessionId} />;
}

export default Session;
