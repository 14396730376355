import React from "react";
import { Button } from "react-bootstrap";
import API_URL from "../../../../../config";

const ReportDownloadButton = ({ sessionId }) => {
  const downloadReport = (e) => {
    e.preventDefault();
    const url = `${API_URL}/sessions/${sessionId}/report_archive`;
    window.location.replace(url);
  };

  return (
    <Button variant="success" className="mr-1" onClick={downloadReport}>
      Выгрузить отчет
    </Button>
  );
};

export default ReportDownloadButton;
