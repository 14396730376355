import { push } from "connected-react-router";
import {
  cacheFacingCrop,
  cacheSku,
  cacheTagCrop,
  initSession,
} from "../actions/session";
import UniconfApi from "../uniconfApi";
import { convertBlobToImage } from "./common";

export const cropImage = (rect, img) => {
  return new Promise((resolve) => {
    const canvas = document.createElement("canvas");
    let minX = Infinity;
    let minY = Infinity;
    let maxX = 0;
    let maxY = 0;
    for (const vtx of rect) {
      minX = Math.min(minX, vtx[0]);
      maxX = Math.max(maxX, vtx[0]);
      minY = Math.min(minY, vtx[1]);
      maxY = Math.max(maxY, vtx[1]);
    }

    canvas.width = maxX - minX;
    canvas.height = maxY - minY;

    const ctx = canvas.getContext("2d");

    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    ctx.save();
    ctx.beginPath();
    ctx.moveTo(rect[0][0] - minX, rect[0][1] - minY);

    for (let i = 1; i < rect.length; i++) {
      ctx.lineTo(rect[i][0] - minX, rect[i][1] - minY);
    }

    ctx.closePath();
    ctx.clip();
    ctx.drawImage(img, -minX, -minY);

    canvas.toBlob(
      (blob) => {
        convertBlobToImage(blob).then((resImage) => {
          const resCanvas = document.createElement("canvas");
          let w = resImage.width;
          let h = resImage.height;
          if (w === 0 || h === 0) {
            w = 200;
            h = 200;
          } else if (resImage.width > resImage.height) {
            h = (h * 200) / w;
            w = 200;
          } else {
            w = (w * 200) / h;
            h = 200;
          }

          resCanvas.width = w;
          resCanvas.height = h;
          const resCtx = resCanvas.getContext("2d");
          resCtx.drawImage(resImage, 0, 0, w, h);

          resCanvas.toBlob(
            (blob) => {
              convertBlobToImage(blob).then((image) => resolve(image));
            },
            "image/jpeg",
            1.0
          );
        });
      },
      "image/jpeg",
      1.0
    );
  });
};

export const cropTag = (position, img) => {
  const [x, y, w, h] = position;
  const rect = [
    [x, y],
    [x, y + h],
    [x + w, y + h],
    [x + w, y],
  ];
  return cropImage(rect, img);
};

export const initSessionEffect = (sessionId) => {
  return async (dispatch) => {
    let resp;
    try {
      resp = await UniconfApi.getSessionReport(sessionId);
    } catch (e) {
      // eslint-disable-next-line
      sessionStorage.removeItem("sessionId");
      dispatch(push("/"));
      return;
    }
    if (resp.data === null) {
      // eslint-disable-next-line
      sessionStorage.removeItem("sessionId");
      dispatch(push("/"));
    } else {
      dispatch(initSession(resp.data));
    }
  };
};

export const facingCropEffect = (
  facingId,
  facings,
  photoId,
  originalImages
) => {
  return async (dispatch) => {
    let crop = null;
    const positions = facings.filter((f) => f.id === facingId)[0].positions;
    if (photoId in positions) {
      crop = await cropImage(
        positions[photoId].rectangle,
        originalImages[photoId]
      );
    }

    dispatch(cacheFacingCrop(photoId, facingId, crop));
  };
};

export const tagCropEffect = (
  priceTagId,
  priceTags,
  photoId,
  originalImages
) => {
  return async (dispatch) => {
    let crop = null;
    const positons = priceTags.filter((t) => t.id === priceTagId)[0].positions;
    if (photoId in positons) {
      const position = positons[photoId].bounding_box;
      crop = await cropTag(position, originalImages[photoId]);
    }

    dispatch(cacheTagCrop(photoId, priceTagId, crop));
  };
};

export const skuLoadEffect = (sku) => {
  return async (dispatch) => {
    const img = await UniconfApi.getSku(sku.name);
    dispatch(cacheSku(sku.name, img));
  };
};
