import React from "react";
import { ProgressBar, Button, Alert } from "react-bootstrap";
import useUploadMultipleSessions from "./useUploadMultipleSessions";

const UploadMultipleSessions = ({ loadMode, setLoadMode }) => {
  const {
    sessions,
    handleMultipleSessionsDirectoryEntry,
    loading,
    uploadedFiles,
    filesQuantity,
    error,
    closeUploading,
  } = useUploadMultipleSessions(setLoadMode);
  const sessionId = sessionStorage.getItem("sessionId");
  const visible = !loadMode || loadMode === "multiple_dir";

  if (!visible) return null;
  if (error) {
    return (
      <Alert
        className="m-0"
        variant={"danger"}
        onClose={closeUploading}
        dismissible
      >
        {error}
      </Alert>
    );
  }
  if (loading) {
    return (
      <div>
        {sessions.map((session, i) => (
          <div
            key={session.sessionId}
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "10px 0",
              height: 70,
            }}
          >
            <p style={{ margin: 0 }}>{session.name}</p>
            {session.status === "finished" && (
              <Button
                href={`/sessions/${session.sessionId}`}
                target="_blank"
                style={{ width: 200 }}
              >
                Открыть сессию {session.sessionId} в новой вкладке
              </Button>
            )}
            {session.status === "uploading" && (
              <div>
                <ProgressBar
                  now={(uploadedFiles / filesQuantity) * 100}
                  label={`${((uploadedFiles / filesQuantity) * 100).toFixed(
                    0
                  )}%`}
                />
                <p>
                  Загружено {uploadedFiles} из {filesQuantity} картинок
                </p>
              </div>
            )}
            {session.status === "awaiting" && <p>В очереди...</p>}
            {session.status === "error" && (
              <Alert className="m-0" variant={"danger"}>
                Ошибка при загрузке сессии!
              </Alert>
            )}
          </div>
        ))}
      </div>
    );
  }
  return (
    <>
      {!sessionId ? (
        <Button
          variant="blue"
          className="mt-1 ml-1"
          onClick={handleMultipleSessionsDirectoryEntry}
        >
          Загрузить несколько сессий
        </Button>
      ) : null}
    </>
  );
};

export default UploadMultipleSessions;
