import { useState } from "react";
import { useDispatch } from "react-redux";
import { UploadImagesToSession } from "../../../../effects/loader";
import UniconfApi from "../../../../uniconfApi";
import { collectDirectoryFiles } from "../../utils";

declare global {
  interface Window {
    showDirectoryPicker: any;
  }
}

const useUploadSingleSession = (setLoadMode: (mode: string) => void) => {
  const [uploadedFiles, setUploadedFiles] = useState(0);
  const [filesQuantity, setFilesQuantity] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const dispatch = useDispatch();

  const closeUploading = () => {
    setError("");
    setLoadMode("");
    setLoading(false);
  };

  async function handleSingleSessionDirectoryEntry() {
    try {
      const dirHandle = await window.showDirectoryPicker();
      const sessionId = sessionStorage.getItem("sessionId");
      let uploadSessionId = sessionId;
      if (sessionId === null) {
        const res = await UniconfApi.createSession();
        uploadSessionId = res.data.id;
      }
      setLoading(true);
      setLoadMode("single_dir");
      const files = await collectDirectoryFiles(dirHandle);
      if (!files.length) {
        setError("Фото не были найдены!");
        return;
      }
      setFilesQuantity(files.length);
      const redirectToSession = true;
      await UploadImagesToSession(
        files,
        uploadSessionId,
        dispatch,
        setUploadedFiles,
        redirectToSession
      );
      setLoadMode("");
      uploadSessionId && sessionStorage.setItem("sessionId", uploadSessionId);
    } catch (e: any) {
      // code 20 - The user aborted a directory request.
      if (e.code === 20) return;
      setError("Ошибка при загрузке фото в сессию!");
    } finally {
      setLoading(false);
    }
  }
  return {
    handleSingleSessionDirectoryEntry,
    loading,
    filesQuantity,
    uploadedFiles,
    error,
    closeUploading,
  };
};

export default useUploadSingleSession;
